import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService, EmployeeService } from "../../services";
import { EMPLOYEE_TITLE } from "../../shared";

const ViewEmployee = () => {
  const navigate = useNavigate();

  const urlParams = useParams();
  const [currentEmployee, setCurrentEmployee] =  useState(undefined);
	
  const redirectTo = () => {
		navigate(`/employees/list`)
  }

  useEffect(() => {
    if (!AuthService.canViewEmployees()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (!currentEmployee) {
      EmployeeService.getEmployee(urlParams.id).then((data) => {
        setCurrentEmployee(data.data);
      })
    }
  }, []);
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>{currentEmployee?.name} <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-4 mb-4">
          <div>Username: {currentEmployee?.username}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Name: {currentEmployee?.name}</div> 
        </div>
        <div className="col-md-4 mb-4">
          <div>Chinese Name (中文姓名): {currentEmployee?.name_cn}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Birth Date: {currentEmployee?.birth_date}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Roles: {currentEmployee?.roles?.join(', ')}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Email: {currentEmployee?.email}</div> 
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Driver Capacity: {currentEmployee?.driver_capacity}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Phone: {currentEmployee?.phone}</div> 
        </div>
        <div className="col-md-4 mb-4">
          <div>Mobile Phone: {currentEmployee?.mobile_phone}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Home Phone: {currentEmployee?.home_phone}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Language: {currentEmployee?.language}</div>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Employment Type: {currentEmployee?.employment_status}</div> 
        </div>
        <div className="col-md-4 mb-4">
          <div>Address: {currentEmployee?.address}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Title: {EMPLOYEE_TITLE[currentEmployee?.title]}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Title CN(中文称谓): {currentEmployee?.title_cn}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Department: {currentEmployee?.department}</div> 
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Date Hired: {currentEmployee?.date_hired}</div>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Note: {currentEmployee?.note}</div> 
				</div>
        <div className="col-md-4 mb-4">
			  	<div>Tags: {currentEmployee?.tags?.join(', ')}</div> 
				</div>
      </div>
    </>
  );
};

export default ViewEmployee;