import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from 'react-router-dom';
import { transRoutesSlice, driverSlice, vehicleSlice, transRouteTemplatesSlice } from "./../../store";
import { AuthService } from "../../services";
import moment from "moment";

const TransRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchAllRoutes, fetchAllTomorrowRoutes, fetchAllBreakfastRecords, fetchAllLunchRecords, fetchAllSnackRecords } = transRoutesSlice.actions;
  const { fetchAllDrivers } = driverSlice.actions;
  const { fetchAllVehicles } = vehicleSlice.actions;
  const { fetchAllRouteTemplates } = transRouteTemplatesSlice.actions;
  const backToAdmin = () => {
    navigate('/admin');
  }
  useEffect(() => {
    if (!AuthService.canAddOrEditRoutes() && !AuthService.canViewRoutes()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change a dispatcher or admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    dispatch(fetchAllDrivers());
    dispatch(fetchAllVehicles());
    dispatch(fetchAllRoutes());
    const params = new URLSearchParams(window.location.search);
    const scheduleDate = params.get('dateSchedule');
    const requestBody = scheduleDate ? {dateText: moment(scheduleDate).format('MM/DD/YYYY')}: {};
    dispatch(fetchAllTomorrowRoutes(requestBody));
    dispatch(fetchAllRouteTemplates());
    dispatch(fetchAllBreakfastRecords());
    dispatch(fetchAllLunchRecords());
    dispatch(fetchAllSnackRecords());
    const timeInterval = setInterval(
      () => { dispatch(fetchAllRoutes()); }, 180000
    );
    return () => clearInterval(timeInterval);
  }, [])

  return (
    <div className="list row">
      <div className="col-md-12">
      <div className="float-end"><button className="btn btn-link btn-sm" onClick={() => backToAdmin() }>Back To Admin</button></div>
        <div className="float-end"><button className="btn btn-link btn-sm" onClick={() => AuthService.logout()}>Log Out</button></div>
        <Outlet />
      </div>
    </div>
  );
};

export default TransRoutes;