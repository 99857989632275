import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthService, VehicleService } from "../../services";

const VehicleList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState([]);
  const [tag, setTag] = useState('');

  useEffect(() => {
    if (!AuthService.canAddOrEditVechiles()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    VehicleService.getAll().then((data) =>
      setVehicles(data.data)
    );
  }, []);

  const redirectToAdmin = () => {
    navigate(`/admin/customer-report`)
  }

  const goToEdit = (id) => {
    navigate(`/vehicles/edit/${id}?redirect=list`)
  }


  const goToView = (id) => {
    navigate(`/vehicles/${id}`)
  }

  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>All Vehicles <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12">
          <div className="mb-4">Filter By Tag: <input type="text" value={tag} onChange={(e) => setTag(e.currentTarget.value)}/></div>
          <table className="personnel-info-table"> 
            <thead>
              <tr>
                <th>Vehicle Number</th>
                <th>Tag Number</th>
                <th>Mileage</th>
                <th>Status</th>
                <th>Capacity</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                vehicles && vehicles.filter((item)=> item?.tag.toLowerCase().includes(tag.toLowerCase()))?.sort((a, b) => parseInt(a.vehicle_number)-parseInt(b.vehicle_number)).map(vehicle => <tr key={vehicle.id}>
                  <td>{vehicle?.vehicle_number}</td>
                  <td>{vehicle?.tag}</td>
                  <td>{vehicle?.mileage}</td>
                  <td>{vehicle?.status}</td>
                  <td>{vehicle?.capacity}</td>
                  <td>
                    {AuthService.canAddOrEditVechiles() && <button className="btn btn-primary btn-sm me-2" onClick={() => goToEdit(vehicle?.id)}>Edit</button> }
                    {AuthService.canViewVechiles() && <button className="btn btn-default btn-sm me-2" onClick={() => goToView(vehicle?.id)}>View</button>}
                  </td>
                </tr>)
              }
            </tbody>
          </table>
           
        </div>
      </div>
    </>
  )
};

export default VehicleList;