import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerService, SignatureRequestService } from "../../services";
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';

const DriverSignature = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [signatureRequest, setSignatureRequest] = useState(undefined);

  const clearCanvas = () => {
      const canvas = document.getElementsByClassName('sigCanvas')[0];
      if (canvas) {
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
      }

  }

  const submitSignature = () => {
    var fabricCanvas = document.getElementsByClassName('sigCanvas')[0];  //real ID here
    var scaledCanvas = document.createElement('canvas');    //off-screen canvas

    scaledCanvas.width = 400;  //size of new canvas, make sure they are proportional
    scaledCanvas.height = 200; //compared to original canvas

    // scale original image to new canvas
    var ctx = scaledCanvas.getContext('2d');
    ctx.drawImage(fabricCanvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
    const dateArr = moment(signatureRequest?.route_date)?.format('MM/DD/YYYY')?.split('/') || [];
    const fileName = `${signatureRequest?.route_id}_${signatureRequest?.driver_id}_${dateArr[0]}_${dateArr[1]}`;
    scaledCanvas.toBlob(function(blob) {
      const formData = new FormData();
      formData.append('file', blob, `${fileName}.jpg`);
      
      CustomerService.uploadAvatar(fileName, formData).then(() => {
        SignatureRequestService.updateSignatureRequest(signatureRequest?.id, { status: 'done'}).then(data => {
            SignatureRequestService.getSignatureRequestById(urlParams.id).then((data) => {
              setSignatureRequest(data?.data);
            })
        })
      })
    })
  }

  useEffect(() => {
    SignatureRequestService.getSignatureRequestById(urlParams.id).then((data) => {
      setSignatureRequest(data?.data);
    })
  }, []);


  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Driver Signature Collection</h5> 
        </div>
        {
            signatureRequest?.status === 'done' &&
            <div className="list row">
				<div className="col-md-12">
                    The signature has been submitted. 
                </div>
            </div>
        }
            
        {
            signatureRequest?.status === 'active' && 
            <div className="list row">
				<div className="col-md-12">
                    <div>Draw your signature below:</div>
                    <SignatureCanvas penColor='black'
                    canvasProps={{width: 1000, height: 500, className: 'sigCanvas'}} />
                </div>
                <div className="col-md-12">
                    <button className="btn btn-primary me-2" onClick={() => submitSignature()}>Submit</button>
                    <button className="btn btn-default" onClick={() => clearCanvas()}>Clear</button>
                </div>
            </div>
        }

        {
            (!signatureRequest || signatureRequest?.status === 'inactive') && 
            <div className="list row">
				<div className="col-md-12">
                    Invalid Sigature Request
                </div>
            </div>
        }
        
      </div>
      <></>
    </>
      
  );
};

export default DriverSignature;