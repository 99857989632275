import React, { useEffect, useState } from "react";
import { TransRoutesService } from "../../services";
import { useDispatch } from "react-redux";
import { transRoutesSlice } from "./../../store";
import moment from 'moment';


const LunchSection = ({transRoutes, lunchRecords, sectionName, confirmHasLunch, removeLunchRecord}) => {
	const [customers, setCustomers] = useState([]);
	const dispatch = useDispatch();
	const { fetchAllLunchRecords }  = transRoutesSlice.actions;

	const createLunchForAll = async () => {
		for (const c of customers) {
			await TransRoutesService.createLunchRecords({
				customer_id: c?.customer_id,
				customer_name: c?.customer_name,
				has_lunch: true,
				date: moment(new Date()).format('MM/DD/YYYY'),
				create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
				create_date: new Date(),
				edit_history: [{
					employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
					date: new Date()
				}]
			})
		};
		dispatch(fetchAllLunchRecords());
	}

	useEffect(() => {
		const routeCustomers = TransRoutesService.getAllInCenterCustomersFromRoutesForLunch(transRoutes, lunchRecords);
		setCustomers(routeCustomers);
	}, [lunchRecords, transRoutes]);



	
  
  return (
    <>
      <div className="text-primary mb-4"><h5>{sectionName} {` (${customers?.length})`}</h5></div>
	  {lunchRecords?.length <= 0 && (<div className="mb-2 mt-2"><button onClick={() => createLunchForAll()} className="btn btn-primary btn-sm">Confirm All Lunch</button></div>)}
      <div className="list row">
        <div className="col-md-12">
					<table className="personnel-info-table">
            <thead>
              <tr>
								<th>Name</th>
								<th>Has Lunch Today</th>
								<th>Change Lunch Status</th>
							</tr>
						</thead>
						<tbody>
							{
								lunchRecords?.length >0 &&  customers?.map((customer) => (
									<tr className={customer?.has_lunch ? 'light-green' : 'red'}>
										<td>{customer?.customer_name}</td>
										<td>{customer?.has_lunch ? 'Yes': 'No'}</td>
										<td>
											{!customer?.has_lunch && <button className="btn btn-link btn-sm" onClick={() => confirmHasLunch(customer)}>Confirm Customer Has Lunch</button>}
											{customer?.has_lunch && <button className="btn btn-link btn-sm" onClick={() => removeLunchRecord(customer?.customer_id)}>Mark Customer NOT have lunch</button>}
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
        </div>
      </div>
    </>
    
  );
};

export default LunchSection;