import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import UsersList from "./components/UsersList";
import TransRoutes from './components/trans-routes/TransRoutes';
import RoutesDashboard from "./components/trans-routes/RoutesDashboard";
import RouteView from "./components/trans-routes/RouteView";
import RouteEdit from "./components/trans-routes/RouteEdit";
import RoutesSchedule from "./components/trans-routes/RoutesSchedule";
import CreateVehicle from "./components/vehicles/CreateVehicle";
import UpdateVehicle from "./components/vehicles/UpdateVehicle";
import CreateEmployee from "./components/employees/CreateEmployee";
import UpdateEmployee from "./components/employees/UpdateEmployee";
import EmployeeList from "./components/employees/EmployeeList";
import ViewEmployee from "./components/employees/ViewEmployee";
import CreateRoute from "./components/trans-routes/CreateRoute";
import Admin from './components/admin/Admin';
import CustomerReport from "./components/admin/CustomerReport";
import Login from "./components/login/Login";
import RoutesHistory from "./components/trans-routes/RoutesHistory";
import RouteTemplatesList from "./components/trans-routes/RouteTemplatesList";
import RouteTemplateEdit from "./components/trans-routes/RouteTemplateEdit";
import CreateCustomer from "./components/customers/CreateCustomer";
import CustomersList from "./components/customers/CustomersList";
import ViewCustomer from "./components/customers/ViewCustomer";
import UpdateCustomer from "./components/customers/UpdateCustomer";
import Landing from "./components/landing/Landing";
import VehicleList from "./components/vehicles/VehicleList";
import ViewVehicle from "./components/vehicles/ViewVehicle";
import CreateMessage from "./components/messages/CreateMessage";
import UpdateMessage from "./components/messages/UpdateMessage";
import MessageList from "./components/messages/MessageList";
import CreateCenterPhone from "./components/center-phone/CreateCenterPhone";
import UpdateCenterPhone from "./components/center-phone/UpdateCenterPhone";
import CenterPhoneList from "./components/center-phone/CenterPhoneList";
import MessageTokenEditor from "./components/messages/MessageTokenEditor";
import SendMessage from "./components/messages/SendMessage";
import SentMessageList from "./components/messages/SentMessageList";
import RouteSignatureList from "./components/trans-routes/RoutesSignature";

import CreateResource from "./components/resources/CreateResource";
import ResourcesList from "./components/resources/ResourcesList";
import UpdateResource from "./components/resources/UpdateResource";
import ViewResource from "./components/resources/ViewResource";

import CreateEvent from "./components/events/CreateEvent";
import EventsList from "./components/events/EventsList";
import EventsMultipleList from "./components/events/EventsMultipleList";
import UpdateEvent from "./components/events/UpdateEvent";
import ViewEvent from "./components/events/ViewEvent";
import EventsCalendar from "./components/events/EventsCalendar";

import Medical from "./components/medical/Medical";
import MedicalIndex from './components/medical/MedicalIndex';
import CreateEventRequest from "./components/event-request/CreateEventRequest";
import EventRequestList from "./components/event-request/EventRequestList";

import DriverSignature from "./components/signature/DriverSignature";


function App() {
  const [showMenu, setShowMenu] = useState(false);
  // const getLogo = () => {
  //   return (window.location.hostname.includes('worldshine2.mayo.llc') || window.location.hostname.includes('site2') || window.location.host.includes('ws2') ||window.location.hostname.includes('localhost')) ? "/images/logo2.png" : ((window.location.hostname.includes('worldshine3.mayo.llc') ||window.location.hostname.includes('site3') || window.location.hostname.includes('ws3')) ? "/images/logo3.png" : "/images/logo1.png");
  // }
  const getLogoSuffix = () => {
    return (window.location.hostname.includes('worldshine2.mayo.llc') || window.location.hostname.includes('site2') || window.location.host.includes('ws2') ||window.location.hostname.includes('localhost')) ? "Care LLC" : ((window.location.hostname.includes('worldshine3.mayo.llc') ||window.location.hostname.includes('site3') || window.location.hostname.includes('ws3')) ? "Cloverleaf LLC" : "International LLC");
  }
  // For Test Site only
  // const getLogo = () => {
  //   return (window.location.hostname.includes('ws2') || window.location.hostname.includes('site2') ||window.location.hostname.includes('localhost')) ? "/images/logo2.png" : ((window.location.hostname.includes('ws3') ||window.location.hostname.includes('site3')) ? "/images/logo3.png" : "/images/logo1.png");
  // }

  const goToAdmin = () => {
    window.location.href = `/admin`;
  }

  return (
    <>
      <div className="logo">
        <img src="/images/logo_new.jpg" /><span className="logo-suffix">{getLogoSuffix()}</span>
        {showMenu && <Navbar bg="#fff" expand="lg" className="admin-nav mb-4 ms-4">
          <Container>
            <Nav.Link onClick={() => goToAdmin()}>Home</Nav.Link>
          </Container>
        </Navbar>}
        
      </div>
      <div className="container container-fixed">
        <Router>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/login" element={<Login setMenu={() => {setShowMenu(true)}}/>} />
            <Route path="/trans-routes" element={<TransRoutes />}>
              <Route path="" element={<Navigate replace to="dashboard" />} />
              <Route path="create" element={<CreateRoute/>} />
              <Route path="dashboard" element={<RoutesDashboard/>} />
              <Route path=":id" element={<RouteView/>} />
              <Route path="edit/:id" element={<RouteEdit/>} />
              <Route path="schedule" element={<RoutesSchedule />} />
              <Route path="history" element={<RoutesHistory />} />
              <Route path="templates" element={<RouteTemplatesList />} />
              <Route path="templates/edit/:id" element={<RouteTemplateEdit />} />
              <Route path="route-signature" element={<RouteSignatureList/>} />
            </Route>
            <Route path="/signature/:id" element={<DriverSignature/>} />
            <Route path="/vehicles" element={<CreateVehicle /> } />
            <Route path="/vehicles/edit/:id" element={<UpdateVehicle />} />
            <Route path="/vehicles/list" element={<VehicleList/> } />
            <Route path="/vehicles/:id" element={<ViewVehicle/>}/>

            <Route path="/messages" element={<CreateMessage /> } />
            <Route path="/messages/edit/:id" element={<UpdateMessage />} />
            <Route path="/messages/list" element={<MessageList/> } />
            <Route path="/message-tokens" element={<MessageTokenEditor/>} />
            <Route path="/messages/send-message" element={<SendMessage/>} />
            <Route path="/messages/sent-messages/list" element={<SentMessageList />} />

            <Route path="/center-phones" element={<CreateCenterPhone /> } />
            <Route path="/center-phones/edit/:id" element={<UpdateCenterPhone />} />
            <Route path="/center-phones/list" element={<CenterPhoneList/> } />
              
            <Route path="/employees" element={<CreateEmployee /> } />
            <Route path="/employees/list" element={<EmployeeList/> } />
            <Route path="/employees/:id" element={<ViewEmployee /> } />
            <Route path="/employees/edit/:id" element={<UpdateEmployee /> } />
            <Route path="/users" element={<UsersList />} />
            <Route path="/customers" element={<CreateCustomer /> } />
            <Route path="/customers/list" element={<CustomersList/> } />
            <Route path="/customers/:id" element={<ViewCustomer /> } />
            <Route path="/customers/edit/:id" element={<UpdateCustomer /> } />
            <Route path="/admin" element={<Admin />}>
              <Route path="" element={<Navigate replace to="customer-report" />} />
              <Route path="customer-report" element={<CustomerReport/>} />
            </Route>
            
            <Route path="/medical" element={<Medical />}>
              <Route path="" element={<Navigate replace to="index" />} />
              <Route path="index" element={<MedicalIndex/>} />
              <Route path="resources" element={<CreateResource /> } />
              <Route path="resources/list" element={<ResourcesList/> } />
              <Route path="resources/:id" element={<ViewResource /> } />
              <Route path="resources/edit/:id" element={<UpdateResource /> } />

              <Route path="events" element={<CreateEvent /> } />
              <Route path="events/list" element={<EventsList/> } />
              <Route path="events/multiple-list" element={<EventsMultipleList/> } />
              <Route path="events/calendar" element={<EventsCalendar/> } />
              <Route path="events/:id" element={<ViewEvent /> } />
              <Route path="events/edit/:id" element={<UpdateEvent /> } />
              <Route path="events/create-from-request" element={<UpdateEvent /> } />
              <Route path="events/request" element={<CreateEventRequest />} />
              <Route path="events/request/list" element={<EventRequestList />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </>
    
  );
}

export default App;