import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomerService, TransRoutesService } from "../../services";
import { selectAllActiveDrivers, selectAllActiveVehicles } from "./../../store";
import moment from 'moment';
import DatePicker from "react-datepicker";

const RouteSignatureList = () => {
  const params = new URLSearchParams(window.location.search);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  
  const navigate = useNavigate();
  const [dateSelected, setDateSelected] = useState(new Date());
  const [routes, setRoutes] = useState([]);

  const redirectToDashboard = () => {
    navigate(`/trans-routes/dashboard`);
  }

  const getAllUniqueCustomers = (routes) => {
    let result = [];
    for (const route of routes) {
      const customerList = route.route_customer_list.map(item => Object.assign({}, item, {routeType: route.type, routeId: route.id, route: route}))
      for (const customer of customerList) {
        const existItem = result.find((item => (item.customer_id === customer.customer_id) || (item?.customer_name?.replaceAll(' ', '')?.toLowerCase() === customer?.customer_name?.replaceAll(' ', '')?.toLowerCase()) ));
        if (existItem) {
          result = result.filter(item => item !== existItem);
          const newItem = Object.assign({}, existItem, {
            customer_enter_center_time: existItem?.customer_enter_center_time || customer?.customer_enter_center_time,
            customer_leave_center_time: existItem?.customer_leave_center_time || customer?.customer_leave_center_time,
            customer_pickup_time: existItem?.customer_pickup_time || customer?.customer_pickup_time,
            customer_dropoff_time: existItem?.customer_dropoff_time || customer?.customer_dropoff_time
          })
          result.push(newItem);
        } else {
          result.push(customer);
        }
      }
    }
    return result.sort((a, b) => {
      if (a.customer_id < b.customer_id) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  useEffect(() => {
    TransRoutesService.getAll(moment(dateSelected)?.format('MM/DD/YYYY')).then(data => {
      const routesResults = data.data;
      const finalRoutes = routesResults.map(async (routeItem) => {
        const dateArr = moment(dateSelected)?.format('MM/DD/YYYY')?.split('/') || [];
        try {
          const result = await CustomerService.getAvatar(`${routeItem.id}_${routeItem.driver}_${dateArr[0]}_${dateArr[1]}`);
          return result?.data ? Object.assign({}, routeItem, {signature: result?.data}) : routeItem;
        } catch (ex) {
          return routeItem;
        }
      });
      Promise.all(finalRoutes).then(finalRoutesData => {
        setRoutes(finalRoutesData);
      })
    })
  }, [dateSelected])


  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>View Routes Signatures <button className="btn btn-link btn-sm" onClick={() => {redirectToDashboard()}}>Back To Dashboard</button></h5> 
        </div>
      </div>
      
      <div className="list row">
				<div className="col-md-12">
					<div className="mb-4 col-md-4 col-sm-12">
						Select Date to start: <DatePicker selected={dateSelected} onChange={(v) => setDateSelected(v)} />
					</div>
					<table className="personnel-info-table me-4">
            <thead>
              <tr>
                <th>Route Name</th>
                <th>Driver</th>
                <th>Route End Time</th>
                <th>Route Type</th>
								<th>Signature</th>
              </tr>
            </thead>
            <tbody>
							{
								routes && routes?.map(({id, name, end_time, driver, type, signature}, index) => {
									return (<tr key={index}>
										<td>{name}</td>
										<td>{drivers.find((d) => d.id === driver)?.name}</td>
										<td>{end_time?moment(end_time).format('HH:ss'): ''}</td>
                    <td>{type}</td>
										<td>
                      {/* {images.find((img) => img.id === id)?.image && <img width="100px" src={`data:image/jpg;base64, ${images.find((img) => img.id === id)?.image}`}/>} */}
                      {signature && <img width="100px" src={`data:image/jpg;base64, ${signature}`}/>}
                    </td>
									</tr>)
								})
							}
						</tbody>
					</table>
          <hr></hr>
          <table className="personnel-info-table">
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Pickup Time</th>
                <th>Enter Center Time</th>
								<th>Leave Center Time</th>
                <th>Drop off TIme</th>
              </tr>
            </thead>
            <tbody>
							{
								getAllUniqueCustomers(routes)?.map(({customer_name, customer_enter_center_time, customer_dropoff_time, customer_leave_center_time, customer_pickup_time}, index) => {
									return (<tr key={index}>
										<td>{customer_name}</td>
										<td>{customer_pickup_time ? new Date(customer_pickup_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : ''}</td>
										<td>{customer_enter_center_time ? new Date(customer_enter_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}): ''}</td>
                    <td>{customer_leave_center_time ? new Date(customer_leave_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}): ''}</td>
										<td>{customer_dropoff_time ? new Date(customer_dropoff_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : ''}</td>
									</tr>)
								})
							}
						</tbody>
					</table>
				</div>
			</div>
    </>
    
  );
};

export default RouteSignatureList;