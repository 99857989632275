import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  routes: [],
  tomorrowRoutes: [],
  historyRoutes: [],
  breakfasts: [],
  lunches: [],
  snacks: [],
  loading: false,
  breakfastsLoaded: false,
  error: null
};

const fetchAllHistoryRoutes = (state, action) => {
  state.loading = true;
};

const fetchAllHisotryRoutesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.historyRoutes = action.payload;
}

const fetchAllHistoryRoutesFailure = (state, action) => {
  state.historyRoutes = [];
  state.error = action.payload;
};

const fetchAllLunchRecords = (state, action) => {
  state.loading = true;
};

const fetchAllLunchRecordsSuccess = (state, action) => {
  state.lunches = action.payload;
  state.error = null;
  state.loading = false;
};

const fetchAllLunchRecordsFailure = (state, action) => {
  state.lunches = [];
  state.error = action.payload;
}

const fetchAllSnackRecords = (state, action) => {
  state.loading = true;
};

const fetchAllSnackRecordsSuccess = (state, action) => {
  state.snacks = action.payload;
  state.error = null;
  state.loading = false;
};

const fetchAllSnackRecordsFailure = (state, action) => {
  state.snacks = [];
  state.error = action.payload;
};

const fetchAllBreakfastRecords = (state, action) => {
  state.loading = true;
  state.breakfastsLoaded = false;
};

const fetchAllBreakfastRecordsSuccess = (state, action) => {
  state.breakfasts = action.payload;
  state.error = null;
  state.loading = false;
  state.breakfastsLoaded = true;
};

const fetchAllBreakfastRecordsFailure = (state, action) => {
  state.breakfasts = [];
  state.error = action.payload;
  state.breakfastsLoaded = false;
}

const fetchAllRoutes = (state, action) => {
  state.loading = true;
};
const fetchAllRoutesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.routes = action.payload;
};
const fetchAllRoutesFailure = (state, action) => {
  state.routes = [];
  state.error = action.payload;
};

const fetchAllTomorrowRoutes = (state, action) => {
  state.loading = true;
};
const fetchAllTomorrowRoutesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.tomorrowRoutes = action.payload;
};
const fetchAllTomorrowRoutesFailure = (state, action) => {
  state.tomorrowRoutes = [];
  state.error = action.payload;
};

const updateRoute = (state, action) => {};
const updateRouteFailure = (state, action) => {
  state.error = action.payload;
};

const createRoute = (state, action) => {};
const createRouteFailure = (state, action) => {
  state.error = action.payload;
}

export const transRoutesSlice = createSlice({
  name: "transRoutes",
  initialState,
  reducers: {
    fetchAllRoutes,
    fetchAllRoutesSuccess,
    fetchAllRoutesFailure,
    updateRoute,
    updateRouteFailure,
    fetchAllTomorrowRoutes,
    fetchAllTomorrowRoutesSuccess,
    fetchAllTomorrowRoutesFailure,
    createRoute,
    createRouteFailure,
    fetchAllHisotryRoutesSuccess,
    fetchAllHistoryRoutes,
    fetchAllHistoryRoutesFailure,
    fetchAllBreakfastRecords,
    fetchAllBreakfastRecordsFailure,
    fetchAllBreakfastRecordsSuccess,
    fetchAllLunchRecords,
    fetchAllLunchRecordsFailure,
    fetchAllLunchRecordsSuccess,
    fetchAllSnackRecords,
    fetchAllSnackRecordsSuccess,
    fetchAllSnackRecordsFailure
  }
});

