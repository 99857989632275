import http from "../http-common";
import { PERSONAL_ROUTE_STATUS } from "../shared/constants";
const getAll = (scheduleDate, driverId) => {
  const params = {}
  if (driverId) {
    params.driverId = driverId;
  }
  if (scheduleDate) {
    params.scheduleDate = scheduleDate
  }
  return http.get('/routes', {
    params
  });
};

const updateRoute = (id, data) => {
	return http.put(`/routes/${id}`, data);
};

const createNewRoute = (data) => {
	return http.post('/routes', data);
};

const deleteRoute = (id) => {
	return http.delete(`/routes/${id}`);
}

const getAllBreakfastRecords = (date) => {
  const params = { date }
  return http.get('/breakfasts', {
    params
  })
}

const createBreakfastRecords = (data) => {
  return http.post('/breakfasts', data);
}

const deleteBreakfastRecords = (id) => {
  return http.delete(`/breakfasts/${id}`);
}

const getAllLunchRecords = (date) => {
  const params = { date }
  return http.get('/lunches', {
    params
  })
}

const createLunchRecords = (data) => {
  return http.post('/lunches', data);
}

const deleteLunchRecords = (id) => {
  return http.delete(`/lunches/${id}`);
}

const getAllSnackRecords = (date) => {
  const params = { date }
  return http.get('/snacks', {
    params
  })
}

const createSnackRecords = (data) => {
  return http.post('/snacks', data);
}

const deleteSnackRecords = (id) => {
  return http.delete(`/snacks/${id}`);
}

const getAllInCenterCustomersFromRoutes = (routes, breakfasts) => {
  let result = [];
  for (const route of [...routes]) {
    const customerList = route.route_customer_list.filter((i) => (i.customer_route_status === PERSONAL_ROUTE_STATUS.IN_CENTER) && !result.find(r => r.customer_id === i.customer_id))?.map(item => Object.assign({}, item, { has_breakfast: !!(breakfasts.find(b => b.customer_id === item.customer_id && b.has_breakfast)) }));
    result = result.concat(customerList);
  }
  return result;
}

const getAllInCenterCustomersFromRoutesForLunch = (routes, lunches) => {
  let result = [];
  for (const route of [...routes]) {
    const customerList = route.route_customer_list.filter((i) => (i.customer_route_status === PERSONAL_ROUTE_STATUS.IN_CENTER) && !result.find(r => r.customer_id === i.customer_id))?.map(item => Object.assign({}, item, { has_lunch: !!(lunches.find(b => b.customer_id === item.customer_id && b.has_lunch)) }));
    result = result.concat(customerList);
  }
  return result;
}

const getAllInCenterCustomersFromRoutesForSnack = (routes, snacks) => {
  let result = [];
  for (const route of [...routes]) {
    const customerList = route.route_customer_list.filter((i) => (i.customer_route_status === PERSONAL_ROUTE_STATUS.IN_CENTER) && !result.find(r => r.customer_id === i.customer_id))?.map(item => Object.assign({}, item, { has_snack: !!(snacks.find(b => b.customer_id === item.customer_id && b.has_snack)) }));
    result = result.concat(customerList);
  }
  return result;
}

const getAllCustomersFromRoutes = (routes, vehicles) => {
  let result = [];
  for (const route of [...routes]) {
    const customerList = route.route_customer_list.filter((i) => !result.find(r => r.customer_id === i.customer_id))?.map(item => Object.assign({}, item, { vehicle_number: vehicles.find(vehicle => vehicle.id === route.vehicle)?.vehicle_number }));
    result = result.concat(customerList);
  }
  return result;
}

export const TransRoutesService = {
  getAll,
	updateRoute,
	createNewRoute,
	deleteRoute,
  getAllBreakfastRecords,
  createBreakfastRecords,
  deleteBreakfastRecords,
  getAllInCenterCustomersFromRoutes,
  getAllCustomersFromRoutes,
  getAllLunchRecords,
  createLunchRecords,
  deleteLunchRecords,
  getAllInCenterCustomersFromRoutesForLunch,
  getAllInCenterCustomersFromRoutesForSnack,
  getAllSnackRecords,
  createSnackRecords,
  deleteSnackRecords
};
