import React, { useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthService } from "../../services";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Admin = () => {
  const navigate = useNavigate();
  const goToCreateEmployee = () => {
    navigate(`/employees`);
  }

  const goToEmployeeList = () => {
    navigate(`/employees/list`);
  }

  const goToCreateCustomer = () => {
    navigate(`/customers`);
  }

  const goToCustomerList = () => {
    navigate(`/customers/list`);
  }

  const goToRoutes = () => {
    navigate(`/trans-routes`);
  }

  const createVechiles = () => {
    navigate(`/vehicles`);
  }

  const goToVehicleList = () => {
    navigate(`/vehicles/list`);
  }

  const goToMedicalList = () => {
    navigate(`/medical/events/list`);
  }

  const goToMedical = () => {
    navigate(`/medical/index`)
  }

  const goToMultipleMedicalList = () => {
    navigate(`/medical/events/multiple-list`);
  }

  const goToCalendarList = () => {
    navigate(`/medical/events/calendar`);
  }

  const goToCreateMedicalEvent = () => {
    navigate(`/medical/events`)
  }

  const goToResourcesList = () => {
    navigate(`/medical/resources/list`);
  }

  const goToCreateResource = () => {
    navigate(`/medical/resources`)
  }

  const goToCreateMessage = () => {
    navigate(`/messages/`);
  }

  const goToMessageList = () => {
    navigate(`/messages/list`);
  }

  const goToCreatePhone = () => {
    navigate(`/center-phones`)
  }

  const goToPhoneList = () => {
    navigate(`/center-phones/list`)
  }

  const goToCreateMessageToken = () => {
    navigate(`/message-tokens`)
  }

  const goToSendMessage = () => {
    navigate(`/messages/send-message`)
  }

  const goToAllSentMessages = () => {
    navigate(`/messages/sent-messages/list`)
  }

  useEffect(() => {
    if (!AuthService.isAdmin()) {
        if (!AuthService.canViewRoutes() && !AuthService.canAddOrEditRoutes() && !AuthService.canViewAttendance() && !AuthService.canAddOrEditAttendance() && !AuthService.canAddOrEditCustomers() &&  !AuthService.canViewCustomers() && !AuthService.canAddOrEditEmployees() && !AuthService.canViewEmployees() && !AuthService.canAddOrEditVechiles() && !AuthService.canViewVechiles()) {
            window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
            AuthService.logout();
            navigate(`/login`);
        }
    }
  }, [])
  return (
    <>
        <div className="list row">
            <div className="col-md-12">
                <div className="float-end"><button className="btn btn-link btn-sm" onClick={() => AuthService.logout()}>Log Out</button></div>
                <h4>Transportation Center </h4> 
            </div>
            <div className="col-md-12">
                <Navbar bg="light" expand="lg" className="admin-nav mb-4">
                  <Container>
                    {/* <Navbar.Brand>Customers Admin</Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      {(AuthService.canAddOrEditEmployees() || AuthService.canViewEmployees()) &&<Nav className="me-auto">
                       <NavDropdown title="Employees" id="basic-nav-dropdown">
                          {AuthService.canAddOrEditEmployees() && <NavDropdown.Item onClick={() => goToCreateEmployee()}>
                            Create New Employee
                          </NavDropdown.Item>}
                          {AuthService.canViewEmployees() && <NavDropdown.Item onClick={() => goToEmployeeList()}>
                            View Employees List
                          </NavDropdown.Item>}
                        </NavDropdown>
                      </Nav> }
                      { (AuthService.canAddOrEditCustomers() || AuthService.canViewCustomers()) && <Nav className="me-auto">
                        <NavDropdown title="Customers" id="basic-nav-dropdown">
                          { AuthService.canAddOrEditCustomers()  && <NavDropdown.Item onClick={() => goToCreateCustomer()}>
                            Create New Customer
                          </NavDropdown.Item>}
                          { AuthService.canViewCustomers() && <NavDropdown.Item onClick={() => goToCustomerList()}>
                            View Customers List
                          </NavDropdown.Item>}
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes()) && <Nav.Link onClick={() => goToRoutes()}>Routes</Nav.Link>}
                      {(AuthService.canAddOrEditVechiles() || AuthService.canViewVechiles()) && <Nav className="me-auto">
                        <NavDropdown title="Vehicles" id="basic-nav-dropdown">
                          {AuthService.canAddOrEditVechiles() && <NavDropdown.Item onClick={() => createVechiles()}>
                            Create New Vehicle
                          </NavDropdown.Item>}
                          {AuthService.canViewVechiles() && <NavDropdown.Item onClick={() => goToVehicleList()}>
                            View Vehicles List
                          </NavDropdown.Item>}
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes()) && <Nav className="me-auto">
                        <NavDropdown title="Messages Templates" id="basic-nav-dropdown">
                          <NavDropdown.Item  onClick={() => goToCreateMessage()}>
                            Create New Message Template
                          </NavDropdown.Item>
                          <NavDropdown.Item   onClick={() => goToMessageList()}>
                            View Message Templates List
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {AuthService.canAccessLegacySystem() && <Nav.Link onClick={() => goToMedical()}>Medical</Nav.Link>}
                      {AuthService.canAccessLegacySystem() && <Nav className="me-auto">
                        <NavDropdown title="Resource" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={() => goToCreateResource()}>
                            Create Resource
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToResourcesList()}>
                            View Resources List
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes()) &&<Nav className="me-auto">
                        <NavDropdown title="Admin Phone" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={() => goToCreatePhone()}>
                            Create New Admin Phone
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToPhoneList()}>
                            View Admin Phones List
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes()) && <Nav className="me-auto">
                        <NavDropdown title="Messages" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={() => goToSendMessage()}>
                            Send Message
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToCreateMessageToken()}>
                            Edit Message Token
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToAllSentMessages()}>
                            View All Sent Messages
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
            </div>
        </div>
        <div className="list row">
            <div className="col-md-12">
                <Outlet />
            </div>
        </div>
    </>
    
  );
};

export default Admin;