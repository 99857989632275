import http from "../http-common";

const getAllSignatureRequests = (params) => {
  return http.get('/signature-requests', { params});
};

const createNewSignatureRequest = (data) => {
  data.status = 'active';
  return http.post('/signature-requests', data);
};

const updateSignatureRequest = (id, data) => {
  return http.put(`/signature-requests/${id}`, data);
}

const deleteSignatureRequest = (id) => {
	return http.delete(`/signature-requests/${id}`)
}

const getSignatureRequestById = (id) => {
	return http.get(`/signature-requests/${id}`);
}


export const SignatureRequestService = {
	getAllSignatureRequests,
	createNewSignatureRequest,
	updateSignatureRequest,
	deleteSignatureRequest,
	getSignatureRequestById
};